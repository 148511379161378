














































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Glide, GlideSlide } from 'vue-glide-js'

import Bacteria from '@/modules/shared/entities/Bacteria'

import PrimaryButton from '@/modules/shared/components/PrimaryButton.vue'
import Icon from '@/modules/shared/components/Icon.vue'

import 'vue-glide-js/dist/vue-glide.css'

const SharedModule = namespace('shared')

@Component({
  components: {
    PrimaryButton,
    Icon,
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
})
export default class HomeBacteria extends Vue {
  @SharedModule.State
  private readonly bacteria!: Bacteria[]

  private get options(): unknown {
    return {
      perView: 5,
      rewind: false,
      bound: true,
      breakpoints: {
        600: {
          perView: 1,
        },
        724: {
          perView: 2,
        },
        960: {
          perView: 3,
        },
      },
    }
  }
}
