

























import { Component, Vue } from 'vue-property-decorator'

import PrimaryButton from '@/modules/shared/components/PrimaryButton.vue'

@Component({
  components: {
    PrimaryButton,
  },
})
export default class HomeHero extends Vue {
  private seeMore() {
    this.$vuetify.goTo('#section-about')
  }
}
