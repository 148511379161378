


















import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import GlobalIcon from '@/modules/shared/components/icons/GlobalIcon.vue'
import Icon from '@/modules/shared/components/Icon.vue'

const SharedModule = namespace('shared')

@Component({
  components: {
    GlobalIcon,
    Icon,
  },
})
export default class ViewsCount extends Vue {
  $refs!: {
    views: HTMLSpanElement;
  }

  @SharedModule.State
  private readonly totalViews!: number

  @SharedModule.Action
  private readonly fetchTotalViews!: () => Promise<void>

  private expanded = false

  private toggleExpand(expanded: boolean) {
    this.expanded = expanded
  }

  private mounted() {
    this.fetchTotalViews()
  }
}
