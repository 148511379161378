








































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Glide, GlideSlide } from 'vue-glide-js'

import Image from '@/modules/shared/entities/Image'

import Icon from '@/modules/shared/components/Icon.vue'

const HomeModule = namespace('home')

@Component({
  components: {
    Icon,
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
})
export default class HomeSupport extends Vue {
  @HomeModule.Getter
  private readonly supportImages!: Image[]

  private get options(): unknown {
    return {
      perView: 8,
      bound: true,
      gap: 18,
      rewind: false,
      breakpoints: {
        600: {
          perView: 2,
        },
        960: {
          perView: 4,
        },
        1024: {
          perView: 6,
        },
      },
    }
  }
}
