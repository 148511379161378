<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.9999 22C14.1041 22 15.8099 17.5228 15.8099 12C15.8099 6.47715 14.1041 2 11.9999 2C9.89574 2 8.18994 6.47715 8.18994 12C8.18994 17.5228 9.89574 22 11.9999 22Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.20996 7.22705H20.789" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2 12H22" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.20996 16.7721H20.789" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'GlobalIcon',
}
</script>
